
import { Vue, Component, Watch } from "vue-property-decorator";
import {
  ProfileType,
  profileTypeArray,
  gradeArray,
} from "@/models/schoolfinder.enums";

import { SchoolSearchCriteria, SchoolSearchModel } from "@/models/interface";
import { TypeaheadModel } from "@/models/typeahead.model";
import { debounce } from "ts-debounce";
import { applicationService } from "@/services/application.service";
import { metaData } from "../assets/js/common-meta-info";
import { setMetaInfo } from "@/assets/js/meta-info-config";
import {
    EntityType,
    entityTypeArray,
} from "@/models/schoolfinder.enums";
@Component({})
export default class Home extends Vue {
  public gradeList = gradeArray;
  showList = false;
  public suggestions: TypeaheadModel[] = [];
  private subString: any;
  private searchText = "";
  private entityType = "";
  private minMatchingChars = 3;
  public schoolSearchCriteria = {} as SchoolSearchCriteria;
  private itemSelected = false;
  public schoolSearchModel = new SchoolSearchModel();
  private forceHide = false;
  public fetchUrl =
    "School/GetSchoolsByName?q=" + this.schoolSearchModel.schoolName;

  public selectedTypeaheadModel: TypeaheadModel | null = null;
  public data = [];
  public SelectedGrade: any;
  private defaultGradeLevel = "--Select--";

  ProfileType = ProfileType;
  profileTypeArray = profileTypeArray;

  async mounted() {
    setMetaInfo({ title: "School Finder Home" });
    this.setData();
    this.schoolSearchModel.gradelevel = this.defaultGradeLevel;
    document.addEventListener("click", (e) => {
      this.forceHide = true;
    });

    document
      .getElementById("typeaheadSearchBar")
      ?.addEventListener("click", (e) => {
        e.stopPropagation();
        this.forceHide = false;
        return false;
      });
  }

  setData() {
    this.selectedTypeaheadModel = new TypeaheadModel(
      null,
      "",
      "",
      "",
      null,
      null,
      ""
    );
  }

  public async onSearchClick() {
    console.log("INSIDE HOME VUE");
    console.log(this.schoolSearchModel.schoolId);
    await this.$store
      .dispatch("app/getLatLng", this.schoolSearchModel.schoolId)
      .then((result: any) => {
        this.schoolSearchCriteria = {
          searchText: this.searchText,
          entityType: this.entityType,
          latitude: result.lat,
          longitude: result.lng,
          distance: 5,
          gradelevel:
            this.schoolSearchModel.gradelevel == this.defaultGradeLevel
              ? ""
              : this.schoolSearchModel.gradelevel,
        };

        this.$store.commit(
          "app/setschoolSearchCriteria",
          this.schoolSearchCriteria
        );

        const url = "/schools";
        console.log(url);
        this.$router.push(url);
      });
  }

  get invalidSearchCriteria(): boolean {
    console.log(this.schoolSearchModel.schoolName);
    console.log(this.schoolSearchModel.gradelevel);

    // return (this.searchText === "" || this.schoolSearchModel.schoolName === "" ||
    //   this.schoolSearchModel.gradelevel === this.defaultGradeLevel);

    return this.searchText === "" || this.schoolSearchModel.schoolName === "";
  }

  selectItem($event: Event, model: TypeaheadModel) {
    let url = "";
    $event.stopPropagation();
    this.selectedTypeaheadModel = model;
    this.itemSelected = true;

    if (this.selectedTypeaheadModel) {
      this.searchText = this.selectedTypeaheadModel.text;
      this.entityType = this.selectedTypeaheadModel.entityType;
      this.schoolSearchModel.schoolName = this.selectedTypeaheadModel.text;
      this.schoolSearchModel.schoolId = this.selectedTypeaheadModel.id;
      this.schoolSearchModel.type = this.selectedTypeaheadModel.type;
      
    }
    const selectedSchoolName = this.schoolSearchModel.schoolName.replaceAll(
      "/",
      "%2F"
    );
    if (
      this.schoolSearchModel.schoolId !== 0 &&
      this.schoolSearchModel.type !== "googleapi"
    ) {
      switch (this.selectedTypeaheadModel.profileType) {
        case this.profileTypeArray[this.ProfileType.K12].name:
        case this.profileTypeArray[this.ProfileType.Both].name:
          url =
            "/schools/" +
            this.schoolSearchModel.schoolId +
            "/about-our-school/" +
            selectedSchoolName;
          break;

        case this.profileTypeArray[this.ProfileType.EarlyChildhood].name:
          url =
            "/schools/" +
            this.schoolSearchModel.schoolId +
            "/ec-school-about/" +
            selectedSchoolName;
          break;
        default:
          break;
      }
      if( this.entityType == entityTypeArray[EntityType.SchoolSystem].name )
      {
      url =
            "/schools/" +
            this.schoolSearchModel.schoolId +
            "/academic-performance/academic-performance";
      }

      console.log(url);
      this.$router.push(url);
    }
  }

  async getSchools() {
    if (this.searchText.length >= this.minMatchingChars) {
      if (this.fetchUrl) {
        const url = `${applicationService.addApiToUrl}/${this.fetchUrl}${this.searchText}`;

        fetch(url)
          .then((response) => {
            return response.json();
          })
          .then((result) => {
            this.suggestions = result.result;
          });
      }
    } else {
      this.suggestions = [];
    }
  }

  lookupItem = debounce(() => {
    this.getSchools();
  }, 500);

  @Watch("searchText", { deep: true })
  private onSearchTextChanged() {
    this.subString = new RegExp(this.searchText, "gi");
    this.lookupItem.call(this.searchText);
  }

  get suggestionsGoogleAPI(): TypeaheadModel[] {
    const suggestions = this.suggestions.filter((s) => s.type === "googleapi");
    return suggestions;
  }

  get suggestionsSchoolDB(): TypeaheadModel[] {
    const suggestions = this.suggestions.filter((s) => s.type !== "googleapi");
    return suggestions;
  }

  get hasSuggestionsFromGoogleAPI(): boolean {
    const suggestions = this.suggestionsGoogleAPI;
    return suggestions && suggestions.length > 0;
  }

  get hasSuggestionsFromSchoolDB(): boolean {
    const suggestions = this.suggestionsSchoolDB;
    return suggestions && suggestions.length > 0;
  }

  suggestionHTMLString(suggestion: string): string {
    const matches = suggestion?.match(this.subString);
    matches?.forEach((element) => {
      suggestion = suggestion.replace(element, "<b>" + element + "</b>");
    });
    return suggestion;
  }

  itemsToShow(): boolean {
    return (
      (this.hasSuggestionsFromGoogleAPI || this.hasSuggestionsFromSchoolDB) &&
      !this.itemSelected
    );
  }
}
